<template>
  <div>
    <Header
      :price="thousandSeparator(outsideData.price)"
      :gross-payment="thousandSeparator(data.GrossPayment)"
      :customer-rate-of-interest="
        formatPercentage(this.data.CustomerRateOfInterest)
      "
    />
    <div class="calculation">
      <div class="calculation__body">
        <div class="calculation__sliders">
          <h2 class="calculation__header">Tilpas din finansiering</h2>

          <Slider
            v-model="downPayment"
            title="Udbetaling"
            :options="downPaymentOptions"
            :dragEndCallback="doCalculate"
          />

          <Slider
            v-model="terms"
            title="Løbetid"
            :options="termsOptions"
            :dragEndCallback="doCalculate"
          />

          <p v-if="showError" ref="error" class="calculation__error">
            Den sammensatte udbetaling og løbetid er ikke mulig
          </p>
        </div>

        <div class="calculation__overview">
          <div class="calculation__text-container">
            <p class="calculation__text">Saml. kreditbeløb</p>
            <p class="calculation__text">
              {{ thousandSeparator(data.FinancingAmountTotal) }} kr.
            </p>
          </div>
          <div class="calculation__text-container">
            <p class="calculation__text">Saml. kreditomk.</p>
            <p class="calculation__text">
              {{ thousandSeparator(data.TotalCreditExpenses) }} kr
            </p>
          </div>
          <div class="calculation__text-container">
            <p class="calculation__text">Variabel rente</p>
            <p class="calculation__text">
              {{ formatPercentage(data.CustomerRateOfInterest) }}%
            </p>
          </div>
          <div class="calculation__text-container">
            <p class="calculation__text">Saml. tilbagebetaling</p>
            <p class="calculation__text">
              {{ thousandSeparator(data.TotalAmountToPay) }} kr.
            </p>
          </div>
          <div class="calculation__text-container">
            <p class="calculation__text">ÅOP</p>
            <p class="calculation__text">
              {{ formatPercentage(data.Fees.AnnualExpensesInPercent) }}%
            </p>
          </div>
        </div>
      </div>

      <p v-html="dutyText" class="calculation__terms"></p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { thousandSeparator } from "@/functions";

import Header from "./Header.vue";
import Slider from "./Slider.vue";

export default {
  components: {
    Header,
    Slider,
  },
  data() {
    return {
      downPayment: this.downPaymentOptions.value,
      terms: this.termsOptions.value,
      data: { Fees: {} },
      showError: false,
    };
  },
  props: {
    termsOptions: undefined,
    downPaymentOptions: undefined,
    isLoadingUpdate: undefined,
    outsideData: undefined,
  },
  computed: {
    dutyText: function () {
      return `${this.data.DutyText} ${this.outsideData.dutyText}`
    }
  },
  methods: {
    doCalculate() {
      this.showErrorHappend(false);
      this.isLoadingUpdate(true);

      var firstRegistrationDate = this.formatDate(this.outsideData.firstRegistrationDate)

      axios
        .get(
          `/api/calculate?dealerId=${this.outsideData.dealerId}&terms=${this.terms}&manufacturedate=${firstRegistrationDate}&price=${this.outsideData.price}&downPayment=${this.downPayment}&loanType=${this.outsideData.loanType}`
        )
        .then((response) => {
          if (response.data === "") {
            this.isLoadingUpdate(false);
            this.showErrorHappend(true);
            return;
          }

          this.data = response.data;

          this.isLoadingUpdate(false);
        });
    },
    showErrorHappend(value) {
      this.showError = value;
    },
    thousandSeparator(value) {
      return thousandSeparator(value);
    },
    formatPercentage(value) {
      if (value === undefined) return undefined;

      return value.toString().replace(".", ",");
    },
    formatDate(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1; //Month from 0 to 11
      const year = date.getFullYear();

      let returnValue = '';
      const seperator = '-';

      returnValue += year;

      returnValue += seperator;

      returnValue += month <= 9 ? '0' + month : month;

      returnValue += seperator;

      returnValue += day <= 9 ? '0' + day : day

      return returnValue;
    }
  },
  created() {
    this.doCalculate();
  },
};
</script>

<style lang="scss" scoped>
.calculation {
  @apply p-6 border border-gray-400 border-t-0;

  &__header {
    @apply mt-4 mb-8 text-2xl;
  }

  &__overview {
    @apply bg-gray-200 p-4;
  }

  &__text-container {
    @apply flex justify-between;
  }

  &__text {
    @apply text-sm leading-7 text-gray-600;
  }

  &__terms {
    @apply mt-4 text-xs text-gray-500;
  }

  &__error {
    @apply bg-red-600 py-4 mt-2 mb-3 text-white;
  }

  @screen lg {
    &__header {
      @apply text-left;
    }

    &__body {
      @apply flex;
    }

    &__sliders {
      @apply w-2/3 pr-6;
    }

    &__overview {
      @apply w-1/3;
    }
  }
}
</style>
